import React from "react";
import "./contact.css";
import TextArea from "./TextArea";
import InputField from "./InputField";
import Social from "../../social/Social";

export const Contact = () => {
  return (
    <div className="contact-container my-container">
      <div className="contact-content">
        <div className="contact-info">
          <h1 className="text-3xl">Hey, I'm Hakim Soukrati !</h1>
          <p>
            I've currently deactivated my portfolio for some personal reasons,
            <br /> but I'll be back soon. Feel free to connect with me through other platforms.
          </p>
          <p>Thank you for your understanding !</p>
          {/* <h1 className="text-3xl">
            Let's work
            <br /> together
          </h1>
          <p>Feel free to fill the form, or contact me directly by email</p> */}
          {/* -------------------email card---------------- */}
          <div className="contact-card">
            <div className="contact-icon">
              <i class="fa-regular fa-envelope"></i>
            </div>
            <div className="contact-card-info">
              <div>Maile me at</div>
              <a href="mailto:hakim.soukrati@gmail.com">hakim.soukrati@gmail.com</a>
            </div>
          </div>
          {/* -------------------phone card---------------- */}
          <div className="contact-card">
            <div className="contact-icon border">
              <i class="fa-solid fa-phone"></i>
            </div>
            <div className="contact-card-info">
              <div>call me at</div>
              <a href="#">+212 625070500</a>
            </div>
          </div>
          {/* -------------------adress card---------------- */}
          {/* <div className="contact-card">
            <div className="contact-icon">
              <i class="fa-solid fa-location-dot"></i>
            </div>
            <div className="contact-card-info">
              <div>current address</div>
              <a href="#">Meknes, Fes-Meknes, Morocco</a>
            </div>
          </div> */}
        </div>
        {/* ------------------- contact form---------------- */}
      </div>
    </div>
  );
};

<div className="contact-form border">
  <h1>
    Send me a message <span className="text-lg">🚀</span>{" "}
  </h1>
  <form action="" method="get" className="mt-8">
    <InputField placeholder="full name*" id="fullName" />
    <InputField placeholder="Email address*" id="email" />
    {/* <InputField placeholder="subject" id="subject" /> */}
    <TextArea labelText={"Message : "} id="message" />
  </form>
  <button className="w-auto button btn mt-6 ">send message</button>
  <Social page={"contact"} />
</div>;
