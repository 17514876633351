import React from "react";
import "./App.css";
import Header from "./components/header/Header";
import Home from "./components/pages/home/Home";
import About from "./components/pages/about/About";
import { Route, Routes, Router, useLocation, HashRouter, Navigate } from "react-router-dom";
import Skills from "./components/pages/skills/Skills";
import { AnimatePresence } from "framer-motion";
import { Contact } from "./components/pages/contact/Contact";
import Portfolio from "./components/pages/portfolio/Portfolio";
const App = () => {
  return <Contact />
  return 
  
  (
    <div>
      <Header />
      <main className="main">
        <AnimatePresence>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/skills" element={<Skills />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/portfolio" element={<Portfolio />} />
            <Route path="/*" element={<Navigate to="/" />} />
          </Routes>
        </AnimatePresence>
      </main>
    </div>
  );
};

export default App;
